import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

import Footer from "../components/footer/footer"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMouse, faSearch, faCartPlus, faCheck, faCalendar, faMobile, faStarOfLife, faInfinity, faCode, faPlug, faStar, faUsers, faCircleNotch, faChartBar, faLightbulb, faPollH, faFileImage, faServer } from '@fortawesome/free-solid-svg-icons'
import { faTrello, faAmazon } from '@fortawesome/free-brands-svg-icons'

import "./index.scss"
import "./our-work.scss"

import ImgAppsLogo1 from "../images/apps/logo-feedtrack-color.svg"
import ImgAppsLogo2 from "../images/apps/logo-ecim-color.svg"
import ImgAppsLogo3 from "../images/apps/logo-spring-color.svg"
import ImgAppsLogo4 from "../images/apps/logo-dmonitor-black.svg"

import ImgAppsFeedtrackMockup1 from "../images/apps/apps-feedtrack-mockup-1.png"
import ImgAppsFeedtrackMockup2 from "../images/apps/apps-feedtrack-mockup-2.png"
import ImgAppsFeedtrackMockup3 from "../images/apps/apps-feedtrack-mockup-3.png"
import VideoAppFeedtrack from "../videos/ftrs-app-feedtrack.mp4"

import ImgAppsSpringMockup1 from "../images/apps/apps-spring-mockup-1.png"
import ImgAppsSpringMockup2 from "../images/apps/apps-spring-mockup-2.png"
import ImgAppsSpringMockup3 from "../images/apps/apps-spring-mockup-3.png"
import ImgAppsSpringMockup4 from "../images/apps/apps-spring-mockup-4.jpg"

import ImgAppsEcimMockup1 from "../images/apps/apps-ecim-mockup-1.jpg"
import ImgAppsEcimMockup2 from "../images/apps/apps-ecim-mockup-2.jpg"
import ImgAppsEcimMockup3 from "../images/apps/apps-ecim-mockup-3.jpg"
import ImgAppsEcimMockup4 from "../images/apps/apps-ecim-mockup-4.jpg"

import ImgAppsDmonitorMockup1 from "../images/apps/apps-dmonitor-mockup-1.png"
import ImgAppsDmonitorMockup2 from "../images/apps/apps-dmonitor-mockup-2.png"
import ImgAppsDmonitorMockup3 from "../images/apps/apps-dmonitor-mockup-3.png"
import ImgAppsDmonitorMockup4 from "../images/apps/apps-dmonitor-mockup-4.png"

import VideoBg from "../videos/ftrs-video.mp4"

import VideoApp1 from "../videos/ftrs-videoapp1.mp4"
import VideoApp2 from "../videos/ftrs-videoapp2.mp4"
import { faCheckSquare } from "@fortawesome/free-regular-svg-icons"

const OurWork = () => (

    <Layout>
        <SEO title="Our Work" description="We make awesome web and mobile apps" />

        <div className="ftrs-background" data-sal="fade" data-sal-duration="2000">
			<video autoPlay="autoplay" muted loop className="ftrs-hero__video ftrs-hero__video--dark">
				<source media="(orientation: landscape)" src={VideoBg} type="video/mp4" />
			</video>
		</div>

        <Container className="ftrs-content ftrs-our-work">

            <h1 data-sal="fade" data-sal-duration="1000" className="ftrs-h1">We make awesome web and&nbsp;mobile apps</h1>

            <Row>
                <Col xs="12" lg="6" xl="6" className="ftrs-our-work__paragraph">
                    <p data-sal="fade" data-sal-duration="1000" className="text-basic">We've taken our extensive experience and&nbsp;re-engineered it into&nbsp;unique, cutting-edge tools that&nbsp;are easy to&nbsp;use and&nbsp;look great. Innovation and&nbsp;a&nbsp;sense of&nbsp;perfection is in&nbsp;our&nbsp;DNA.</p>
                </Col>
                <Col xs="12" lg="6" xl="6" className="ftrs-our-work__paragraph">
                    <p data-sal="fade" data-sal-duration="1000" className="text-basic">For many years we have continuously created, optimized and&nbsp;maintained apps for&nbsp;top financial institutions, automotive, telco and&nbsp;other market leaders. We&nbsp;understand the&nbsp;value and&nbsp;impact of&nbsp;our products and&nbsp;therefore we strive to&nbsp;deliver high-end and&nbsp;bulletproof solutions.</p>
                </Col>
            </Row>

            <p data-sal="fade" data-sal-duration="1000" className="ftrs-h3 ftrs-our-work__h3">Here are a&nbsp;few examples of&nbsp;our&nbsp;work:</p>

        </Container>

        <div className="ftrs-app">
			<div data-sal="fade" data-sal-duration="1000" className="ftrs-app__header ftrs-app__header--feedtrack"></div>
			<Container>

				<div className="ftrs-app__app-header">
					<img data-sal="fade" data-sal-duration="1000" loading="lazy" src={ImgAppsLogo1} alt="feedTRACK" className="ftrs-app__logo" />
					<div><span className="ftrs-app__tab ftrs-app__tab--feedtrack" data-sal="fade" data-sal-duration="1000"># customer experience</span></div>
				</div>

				<Row>
					<Col xs="12" lg="8" xl="6" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-app__stage">
							<div>
								<h2 className="ftrs-app__subtitle">Intelligent Customer Experience Management Tool that easily collects customer feedback and&nbsp;provides instant reporting.</h2>
								<ul className="ftrs-app__description">
									<li>smart emailing</li>
									<li>customisable projects</li>
									<li>instant feedback processing</li>
									<li>clear analysis and reporting</li>
									<li>language localization</li>
									<li>task management</li>
									<li>user access management</li>
									<li>notifications</li>
									<li>data import automation</li>
								</ul>
							</div>
						</div>
					</Col>
					<Col xs="12" lg="4" xl="6" data-sal="fade" data-sal-duration="1000">

						<h2 className="ftrs-app__subtitle mb1">project description:</h2>
						Cloud migration was the&nbsp;obvious choice after the&nbsp;amount of&nbsp;processed data and&nbsp;project size variety grew past a&nbsp;certain level.
						The Cloud gives us the&nbsp;opportunity to&nbsp;scale performance immediately and&nbsp;without the&nbsp;need of&nbsp;permanent oversight.
						The goal of&nbsp;this project was to&nbsp;give feedTRACK more space to&nbsp;breathe and&nbsp;prepare it for&nbsp;an&nbsp;even higher data volume.
					</Col>
				</Row>

				<div className="ftrs-app__video-stage" data-sal="fade" data-sal-duration="1000">
					<div className="ftrs-app__feedtrack-reference" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-app__feedtrack-header">Customer review:</div>
						<div className="ftrs-app__feedtrack-stars">
							<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-star" />
							<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-star" />
							<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-star" />
							<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-star" />
							<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-star" />
						</div>

						<p className="ftrs-app__feedtrack-review">
							It was our first time here, but&nbsp;it won't be the&nbsp;last, that's for&nbsp;sure! The&nbsp;island was incredible, the&nbsp;colors, the&nbsp;ocean and the&nbsp;underwater life was fantastic.
						</p>
						<p className="ftrs-app__feedtrack-review">
							The staff was very kind and&nbsp;helpful and&nbsp;we were so&nbsp;thankful for&nbsp;their help. We&nbsp;loved the&nbsp;food, compliments to&nbsp;the&nbsp;chef!
						</p>

						<div className="ftrs-app__feedtrack-rating">
							<div className="ftrs-app__feedtrack-small-stars">
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
							</div>
							<div className="ftrs-app__feedtrack-category">accomodation</div>
						</div>

						<div className="ftrs-app__feedtrack-rating">
							<div className="ftrs-app__feedtrack-small-stars">
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
							</div>
							<div className="ftrs-app__feedtrack-category">food</div>
						</div>

						<div className="ftrs-app__feedtrack-rating">
							<div className="ftrs-app__feedtrack-small-stars">
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
							</div>
							<div className="ftrs-app__feedtrack-category">entertainment</div>
						</div>

						<div className="ftrs-app__feedtrack-rating">
							<div className="ftrs-app__feedtrack-small-stars">
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star" />
								<FontAwesomeIcon icon={faStar} className="ftrs-app__feedtrack-small-star ftrs-app__feedtrack-small-star--gray" />
							</div>
							<div className="ftrs-app__feedtrack-category">travel &amp; flight</div>
						</div>

					</div>
					<video autoPlay="autoplay" muted loop className="ftrs-app__video-app">
						<source src={VideoApp1} type="video/mp4" />
					</video>
				</div>

				<h2 className="ftrs-app__subtitle mb2 mt2 text-center">services provided:</h2>

				<Row className="mt1 mb2 justify-content-center">
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--feedtrack">
								<FontAwesomeIcon icon={faInfinity} />
							</div>
							digital partnering
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--feedtrack">
								<FontAwesomeIcon icon={faTrello} />
							</div>
							project management
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--feedtrack">
								<FontAwesomeIcon icon={faPlug} />
							</div>
							3rd party api
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--feedtrack">
								<FontAwesomeIcon icon={faAmazon} />
							</div>
							cloud migration
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--feedtrack">
								<FontAwesomeIcon icon={faFileImage} />
							</div>
							design and architecture
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--feedtrack">
								<FontAwesomeIcon icon={faServer} />
							</div>
							devops
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--feedtrack">
								<FontAwesomeIcon icon={faStarOfLife} />
							</div>
							quality assurance
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--feedtrack">
								<FontAwesomeIcon icon={faPollH} />
							</div>
							support &amp; maintenance
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--feedtrack">
								<FontAwesomeIcon icon={faCode} />
							</div>
							precise handicraft
						</div>
					</Col>
				</Row>

				<div className="ftrs-app__preview mb1">

					<Row className="justify-content-center">
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsFeedtrackMockup1} alt="feedTRACK" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsFeedtrackMockup2} alt="feedTRACK" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsFeedtrackMockup3} alt="feedTRACK" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
					</Row>

				</div>

				<Row data-sal="fade" data-sal-duration="1000">
					<Col xs="6" xl="3">
						<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faUsers} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">2.200.000 +</div>
							<div className="ftrs-app__feedtrack-point-description">customers approached every year</div>
						</div>
					</Col>
					<Col xs="6" xl="3">
						<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faCircleNotch} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">up to 30 %</div>
							<div className="ftrs-app__feedtrack-point-description">response rate</div>
						</div>
					</Col>
					<Col xs="6" xl="3">
						<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faChartBar} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">+ 67</div>
							<div className="ftrs-app__feedtrack-point-description">average NPS</div>
						</div>
					</Col>
					<Col xs="6" xl="3">
						<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faLightbulb} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">66000 +</div>
							<div className="ftrs-app__feedtrack-point-description">improvement ideas per year</div>
						</div>
					</Col>
				</Row>

				<div className="ftrs-app__preview mb2 mt1">

					<div className="ftrs-app__mockup ftrs-app__mockup--macbook">
						<div className="ftrs-app__macbook-screen">
							<video autoPlay="autoplay" muted loop className="ftrs-app__macbook-video">
								<source src={VideoAppFeedtrack} type="video/mp4" />
							</video>
						</div>
					</div>

				</div>

			</Container>
        </div>

        <div className="ftrs-app">
			<div data-sal="fade" data-sal-duration="1000" className="ftrs-app__header ftrs-app__header--spring"></div>
			<Container>

				<div className="ftrs-app__app-header">
					<img data-sal="fade" data-sal-duration="1000" loading="lazy" src={ImgAppsLogo3} alt="Spring" className="ftrs-app__logo" />
					<div><span className="ftrs-app__tab ftrs-app__tab--spring" data-sal="fade" data-sal-duration="1000"># market research</span></div>
				</div>

				<Row>
					<Col xs="12" lg="8" xl="6" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-app__stage">
							<div>
								<h2 className="ftrs-app__subtitle mb1">Cutting edge technology for Mystery Shopping project management.</h2>
								<ul className="ftrs-app__description mt1 mb3">
									<li>web &amp; mobile apps for&nbsp;data collection</li>
									<li>instant reporting</li>
									<li>GPS-based location detection</li>
									<li>field workers recruitment</li>
									<li>task management</li>
									<li>media gallery</li>
									<li>questionnaire design tool</li>
									<li>wage management</li>
									<li>quality assurance module</li>
									<li>data import and&nbsp;export</li>
								</ul>
							</div>
						</div>
					</Col>
					<Col xs="12" lg="4" xl="6" data-sal="fade" data-sal-duration="1000">

						<h2 className="ftrs-app__subtitle mb1">project description:</h2>
						<p>This project started about 15&nbsp;years ago with&nbsp;the&nbsp;approach to&nbsp;automate the&nbsp;absolute basics of&nbsp;a&nbsp;mystery shopping branch.</p>
						<p>Since then we've built it up&nbsp;to&nbsp;become a&nbsp;system that automates operations, communication and&nbsp;reporting with&nbsp;many features and&nbsp;new ones being added every sprint.</p>
					</Col>
				</Row>

				<div className="ftrs-app__video-stage" data-sal="fade" data-sal-duration="1000">
					<div className="ftrs-app__feedtrack-reference" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-app__spring-question">Did you feel the seller's full interest in&nbsp;your personal needs?</div>
						<div className="ftrs-app__spring-scale">
							<div className="ftrs-app__spring-scale-item">
								<FontAwesomeIcon icon={faCheckSquare} className="ftrs-app__spring-checkbox" />
								strongly agree
							</div>
							<div className="ftrs-app__spring-scale-item ftrs-app__spring-scale-item--active">
								<FontAwesomeIcon icon={faCheckSquare} className="ftrs-app__spring-checkbox ftrs-app__spring-checkbox--active" />
								agree
							</div>
							<div className="ftrs-app__spring-scale-item">
								<FontAwesomeIcon icon={faCheckSquare} className="ftrs-app__spring-checkbox" />
								neither/nor
							</div>
							<div className="ftrs-app__spring-scale-item">
								<FontAwesomeIcon icon={faCheckSquare} className="ftrs-app__spring-checkbox" />
								disagree
							</div>
							<div className="ftrs-app__spring-scale-item">
								<FontAwesomeIcon icon={faCheckSquare} className="ftrs-app__spring-checkbox" />
								strongly disagree
							</div>
						</div>
					</div>
					<video autoPlay="autoplay" muted loop className="ftrs-app__video-app">
						<source src={VideoApp2} type="video/mp4" />
					</video>
				</div>

				<h2 className="ftrs-app__subtitle mb2 mt2 text-center">services provided:</h2>

				<Row className="mt1 mb2 justify-content-center">
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--spring">
								<FontAwesomeIcon icon={faInfinity} />
							</div>
							digital partnering
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--spring">
								<FontAwesomeIcon icon={faTrello} />
							</div>
							project management
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--spring">
								<FontAwesomeIcon icon={faFileImage} />
							</div>
							design and architecture
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--spring">
								<FontAwesomeIcon icon={faCode} />
							</div>
							precise handicraft
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--spring">
								<FontAwesomeIcon icon={faServer} />
							</div>
							devops
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--spring">
								<FontAwesomeIcon icon={faMobile} />
							</div>
							mobile app 
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--spring">
								<FontAwesomeIcon icon={faPlug} />
							</div>
							3rd party api
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--spring">
								<FontAwesomeIcon icon={faStarOfLife} />
							</div>
							quality assurance
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--spring">
								<FontAwesomeIcon icon={faPollH} />
							</div>
							support &amp; maintenance
						</div>
					</Col>
				</Row>

				<div className="ftrs-app__preview mb1">

					<Row className="justify-content-md-center">
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsSpringMockup1} alt="Spring" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsSpringMockup2} alt="Spring" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsSpringMockup3} alt="Spring" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
					</Row>

				</div>

				<Row data-sal="fade" data-sal-duration="1000">
					<Col xs="6" xl="3">
						<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faCartPlus} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">500.000 +</div>
							<div className="ftrs-app__feedtrack-point-description">completed mystery shoppings</div>
						</div>
					</Col>
					<Col xs="6" xl="3">
						<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faUsers} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">10.000 +</div>
							<div className="ftrs-app__feedtrack-point-description">users</div>
						</div>
					</Col>
					<Col xs="6" xl="3">
						<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faCheck} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">40.000 +</div>
							<div className="ftrs-app__feedtrack-point-description">filled forms per&nbsp;year</div>
						</div>
					</Col>
					<Col xs="6" xl="3">
						<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faCalendar} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">20 +</div>
							<div className="ftrs-app__feedtrack-point-description">years in&nbsp;operation</div>
						</div>
					</Col>
				</Row>

				<div className="ftrs-app__preview mb2 mt1">

					<div className="ftrs-app__mockup ftrs-app__mockup--macbook">
						<div className="ftrs-app__macbook-screen">
							<img src={ImgAppsSpringMockup4} alt="Spring" className="ftrs-app__iphone-image" />
						</div>
					</div>

				</div>

			</Container>
        </div>

        <div className="ftrs-app">
			<div data-sal="fade" data-sal-duration="1000" className="ftrs-app__header ftrs-app__header--ecim"></div>
			<Container>

				<div className="ftrs-app__app-header">
					<img data-sal="fade" data-sal-duration="1000" loading="lazy" src={ImgAppsLogo2} alt="eCIM" className="ftrs-app__logo" />
					<div><span className="ftrs-app__tab ftrs-app__tab--ecim" data-sal="fade" data-sal-duration="1000"># market intelligence</span></div>
				</div>

				<Row>
					<Col xs="12" lg="8" xl="6" data-sal="fade" data-sal-duration="1000">
						<h2 className="ftrs-app__subtitle">Leading market intelligence platform that helps corporations be one&nbsp;step ahead of&nbsp;the&nbsp;competitors.</h2>
						<ul className="ftrs-app__description">
							<li>news</li>
							<li>videos</li>
							<li>social media</li>
							<li>images</li>
							<li>analysis</li>
							<li>email notifications</li>
							<li>newsletters</li>
							<li>data and charts</li>
							<li>user management</li>
							<li>fulltext search</li>
							<li>content categorization</li>
							<li>usage statistics</li>
						</ul>
					</Col>
					<Col xs="12" lg="4" xl="6" data-sal="fade" data-sal-duration="1000">
						<h2 className="ftrs-app__subtitle mb1">project description:</h2>
						<p>The goal with eCIM was to&nbsp;create a&nbsp;platform to&nbsp;aggregate large amounts of&nbsp;data while keeping information well organized and&nbsp;precise. Because of&nbsp;this, users can search for&nbsp;information easily and&nbsp;find what they are looking for within a&nbsp;few clicks.</p>
						<p>In eCIM you'll find your needle in&nbsp;a&nbsp;haystack of&nbsp;data within a&nbsp;few seconds.</p>
					</Col>
				</Row>

				<div className="ftrs-app__preview mt3 mb3">

					<Row className="justify-content-md-center">
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsEcimMockup1} alt="eCIM" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsEcimMockup2} alt="eCIM" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsEcimMockup3} alt="eCIM" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
					</Row>

				</div>

				<h2 className="ftrs-app__subtitle mb2 mt2 text-center">services provided:</h2>

				<Row className="mt1 mb2 justify-content-center">
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--ecim">
								<FontAwesomeIcon icon={faInfinity} />
							</div>
							digital partnering
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--ecim">
								<FontAwesomeIcon icon={faTrello} />
							</div>
							project management
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--ecim">
								<FontAwesomeIcon icon={faFileImage} />
							</div>
							design and architecture
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--ecim">
								<FontAwesomeIcon icon={faCode} />
							</div>
							precise handicraft
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--ecim">
								<FontAwesomeIcon icon={faServer} />
							</div>
							devops
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--ecim">
								<FontAwesomeIcon icon={faStarOfLife} />
							</div>
							quality assurance
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--ecim">
								<FontAwesomeIcon icon={faPollH} />
							</div>
							support &amp; maintenance
						</div>
					</Col>
				</Row>

				<div className="ftrs-app__preview mb2 mt3">

					<div className="ftrs-app__mockup ftrs-app__mockup--macbook">
						<div className="ftrs-app__macbook-screen">
							<img src={ImgAppsEcimMockup4} alt="eCIM" className="ftrs-app__iphone-image" />
						</div>
					</div>

				</div>

				<Row data-sal="fade" data-sal-duration="1000">
					<Col xs="6" xl="3">
						<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faUsers} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">7.000 +</div>
							<div className="ftrs-app__feedtrack-point-description">registered users</div>
						</div>
					</Col>
					<Col xs="6" xl="3">
						<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faSearch} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">1.000 +</div>
							<div className="ftrs-app__feedtrack-point-description">monitored compaines</div>
						</div>
					</Col>
					<Col xs="6" xl="3">
						<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faMouse} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">4.500 +</div>
							<div className="ftrs-app__feedtrack-point-description">hours of&nbsp;development</div>
						</div>
					</Col>
					<Col xs="6" xl="3">
					<div className="ftrs-app__feedtrack-point">
							<div className="ftrs-app__feedtrack-point-icon">
								<FontAwesomeIcon icon={faCalendar} />
							</div>
							<div className="ftrs-app__feedtrack-point-header">13 +</div>
							<div className="ftrs-app__feedtrack-point-description">years in&nbsp;operation</div>
						</div>
					</Col>
				</Row>

			</Container>
        </div>

        <div className="ftrs-app">
			<div data-sal="fade" data-sal-duration="1000" className="ftrs-app__header ftrs-app__header--dmonitor"></div>
			<Container>

				<div className="ftrs-app__app-header">
					<img data-sal="fade" data-sal-duration="1000" loading="lazy" src={ImgAppsLogo4} alt="dMonitor" className="ftrs-app__logo" />
					<div><span className="ftrs-app__tab ftrs-app__tab--dmonitor" data-sal="fade" data-sal-duration="1000"># media monitoring</span></div>
				</div>

				<Row>
					<Col xs="12" lg="8" xl="6" data-sal="fade" data-sal-duration="1000">
						<h2 className="ftrs-app__subtitle">Media monitoring tool that helps companies track the&nbsp;direct communication activities of&nbsp;competitors.</h2>
						<ul className="ftrs-app__description">
							<li>panel management</li>
							<li>images</li>
							<li>videos</li>
							<li>social media</li>
							<li>analysis</li>
							<li>email notifications</li>
							<li>newsletters</li>
							<li>user management</li>
							<li>content categorization</li>
							<li>usage statistics</li>
						</ul>
					</Col>
					<Col xs="12" lg="4" xl="6" data-sal="fade" data-sal-duration="1000">
						<h2 className="ftrs-app__subtitle mb1">project description:</h2>
						<p>With the dMonitor project, our achievement was completely updating and&nbsp;stabilizing a&nbsp;long overdue, but very useful legacy app.</p>
						<p>We completely and&nbsp;smoothly updated and&nbsp;stabilized the&nbsp;app for&nbsp;users without even a&nbsp;single outage. At&nbsp;the same time, we&nbsp;were even able to&nbsp;add new features.</p>
					</Col>
				</Row>

				<div className="ftrs-app__preview mt3 mb3">

					<Row className="justify-content-md-center">
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsDmonitorMockup1} alt="dMonitor" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsDmonitorMockup2} alt="dMonitor" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
						<Col xs="4" data-sal="fade" data-sal-duration="1000">
							<div className="ftrs-app__mockup ftrs-app__mockup--iphone">
								<div className="ftrs-app__iphone-part1"></div>
								<div className="ftrs-app__iphone-screen">
									<img src={ImgAppsDmonitorMockup3} alt="dMonitor" className="ftrs-app__iphone-image" />
								</div>
								<div className="ftrs-app__iphone-part2"></div>
							</div>
						</Col>
					</Row>

				</div>

				<h2 className="ftrs-app__subtitle mb2 mt2 text-center">services provided:</h2>

				<Row className="mt1 mb2 justify-content-center">
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--dmonitor">
								<FontAwesomeIcon icon={faInfinity} />
							</div>
							digital partnering
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--dmonitor">
								<FontAwesomeIcon icon={faTrello} />
							</div>
							project management
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--dmonitor">
								<FontAwesomeIcon icon={faFileImage} />
							</div>
							design and architecture
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--dmonitor	">
								<FontAwesomeIcon icon={faCode} />
							</div>
							precise handicraft
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--dmonitor">
								<FontAwesomeIcon icon={faServer} />
							</div>
							devops
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--dmonitor">
								<FontAwesomeIcon icon={faStarOfLife} />
							</div>
							quality assurance
						</div>
					</Col>
					<Col xs="4" lg="3" xl="2" data-sal="fade" data-sal-duration="1000">
						<div className="ftrs-services-provided__item">
							<div className="ftrs-services-provided__icon ftrs-services-provided__icon--dmonitor">
								<FontAwesomeIcon icon={faPollH} />
							</div>
							support &amp; maintenance
						</div>
					</Col>
				</Row>

				<div className="ftrs-app__preview mb2 mt3">

					<div className="ftrs-app__mockup ftrs-app__mockup--macbook">
						<div className="ftrs-app__macbook-screen">
							<img src={ImgAppsDmonitorMockup4} alt="dMonitor" className="ftrs-app__iphone-image" />
						</div>
					</div>

				</div>

			</Container>
        </div>

		<Footer></Footer>

    </Layout>

)

export default OurWork